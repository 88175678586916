<template>

  <div>
    <indicate-list-filters
      :is-filter-indicate-sidebar-active.sync="isFilterIndicateSidebarActive"

      :search-query.sync="searchQuery"
      :filter-dates-filter.sync="filterDatesFilter"
      :campaigns-filter.sync="campaignsFilter"
      :status-filter.sync="statusFilter"
      :stage-filter.sync="stageFilter"
      :period-date-filter.sync="periodDateFilter"
      :single-date-filter.sync="singleDateFilter"
      :is-single-date.sync="isSingleDate"

      :filter-dates-options="filterDatesOptions"
      :campaigns-options="campaignsOptions"
      :status-options="statusOptions"
      :stage-options="stageOptions"

      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>indicações</label>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                class="btn-icon"
                @click="isFilterIndicateSidebarActive = true"
              >
                <feather-icon
                  icon="FilterIcon"
                />

              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button-toolbar
              key-nav
              aria-label="Toolbar with button groups"
              class="demo-inline-spacing"
            >
              <b-button-group>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="stageFilter === null ? 'primary' : 'outline-primary'"
                  @click="changeStageFilter(null)"
                >
                  <feather-icon icon="UsersIcon" /> Todas as indicações
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="stageFilter === 'Em andamento' ? 'primary' : 'outline-primary'"
                  @click="changeStageFilter('Em andamento')"
                >
                  <feather-icon icon="ClockIcon" /> Em andamento
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="stageFilter === 'Concluído' ? 'primary' : 'outline-primary'"
                  @click="changeStageFilter('Concluído')"
                >
                  <feather-icon icon="CheckIcon" /> Concluídas
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="stageFilter === 'Cancelado' ? 'primary' : 'outline-primary'"
                  @click="changeStageFilter('Cancelado')"
                >
                  <feather-icon icon="XIcon" /> Canceladas
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </b-col>
        </b-row>
        <!-- / Table Top -->

      </div>
      <b-overlay
        :show="isAPICallInProgress"
        rounded="sm"
      >
        <b-table
          ref="refIndicateListTable"
          class="position-relative font12px"
          :items="fetchIndicates"
          responsive
          :fields="tableColumns"
          primary-key="coupon"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Nada Encontrado :("
          :sort-desc.sync="isSortDirDesc"
        >

          <template #head(stage)="{field}">
            <b-row class="position-relative">

              <!-- <feather-icon
              v-b-popover.hover.bottom="'Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.'"
              title="Popover on right"
              size="12"
              icon="HelpCircleIcon"
              class="mr-1"
            /> -->
              <span
                id="popover-stage"
                class="pr-1"
              >
                <feather-icon
                  size="12"
                  icon="HelpCircleIcon"
                />
              </span>
              <feather-icon
                size="18"
                :icon="field.icon"
              />
            </b-row>
          </template>
          <template #head(dates)="{field}">
            <b-row class="position-relative">
              <span
                id="popover-dates"
                class="pr-1"
              >
                <feather-icon
                  size="12"
                  icon="HelpCircleIcon"
                />
              </span>
              {{ field.label }}
            </b-row>
          </template>
          <template #cell(stage)="{item}">
            <b-avatar
              :variant="`light-${resolveIndicateStageVariant(item.stage)}`"
            >

              <feather-icon
                :icon="resolveIndicateStageIcon(item.stage)"
                size="16"
                :class="`text-${resolveIndicateStageVariant(item.stage)}`"
              />
            </b-avatar>
          </template>
          <template #cell(indicated)="data">
            <div>
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.indicated.name }}
              </span>
              <!-- <small class="text-muted d-block">{{ data.item.indicated.email }}</small>
              <small class="text-muted d-block">{{ data.item.indicated.phone }}</small>
              <small
                v-if="data.item.indicated.indicatedCPF"
                class="text-muted d-block"
              >{{ formatCpfCnpjNumber(data.item.indicated.indicatedCPF) }}</small> -->
            </div>
          </template>
          <template #cell(dates)="{item}">
            <div class="text-nowrap">
              <b-badge
                pill
                :variant="`light-success`"
              >
                C
              </b-badge>
              &nbsp;{{ convertDate(item.dates.registrationDate) }}
              <br>
              <b-badge
                pill
                :variant="`light-info`"
              >
                V
              </b-badge>
              &nbsp;{{ item.dates.expireDate !== null ? convertDate(item.dates.expireDate) : 'não expira' }}
              <!-- <br>
              <b-badge
                pill
                :variant="`light-dark`"
              >
                {{ convertDate(item.dates.lastUpdateDate) }}
              </b-badge> -->
            </div>
          </template>
          <template #cell(status)="{item}">
            <div class="text-nowrap">
              <b-badge
                pill
                :variant="`light-${resolveIndicateStatusVariant(item.status)}`"
                style="white-space: break-spaces !important;"
              >
                {{ item.status }}
              </b-badge>
            </div>
          </template>
          <template #cell(actions)="{item}">
            <div
              v-if="$can('edit', 'indications')"
              class="d-flex justify-content-center"
            >
              <b-button
                v-if="item.stage === 'Em andamento'"
                title="Dar baixa"
                variant="primary"
                class="btn-icon rounded-circle"
                @click="handleValidate(item.coupon)"
              >
                <feather-icon icon="LogInIcon" />
              </b-button>
              <span
                v-else
                class="font-weight-bold d-block text-nowrap "
              >
                <small>
                  {{ item.stage }} em
                </small>
                <br>
                {{ convertDate(item.dates.lastUpdateDate) }}
              </span>
            </div>
            <div v-else>
              <feather-icon icon="SlashIcon" />
            </div>
          </template>
          <template #cell(ver)="{item}">
            <div class="d-flex justify-content-center align-items-center">
              <b-button
                v-b-modal.indications-details
                variant="primary-light"
                title="Ver detalhes"
                class="btn-icon rounded-circle"
                @click="setCouponDetails(item.coupon)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-overlay>

      <!-- Footer -->
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} até {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalIndicates"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <!-- modal-validate -->
    <!-- <b-modal id="modal-validate">
      <b-card>
        <b-card-text>
          Ao validar a indicação pela primeira vez, será atualizado automaticamente o status de CUPOM GERADO para CUPOM ATIVADO,  o indicador será notificado e você terá até 30 dias para concluir a indicação.
        </b-card-text>
      </b-card>
    </b-modal> -->

    <!-- popover-stage -->
    <b-popover
      target="popover-stage"
      variant="primary"
      triggers="hover"
      placement="top"
    >
      <template #title>
        <span>Etapa</span>
      </template>
      <div class="p-1 d-flex flex-column align-items-start">
        <b-row class="justify-content-center align-items-center">
          <b-avatar
            variant="light-dark"
            class="mr-1"
          >
            <feather-icon
              icon="ClockIcon"
              size="16"
              class="text-dark"
            />
          </b-avatar>
          <span class="text-dark">Em andamento</span>
        </b-row>
        <b-row class="justify-content-center align-items-center mt-1">
          <b-avatar
            variant="light-success"
            class="mr-1"
          >
            <feather-icon
              icon="CheckIcon"
              size="16"
              class="text-success"
            />
          </b-avatar>
          <span class="text-dark">Concluído</span>
        </b-row>
        <b-row class="justify-content-center align-items-center mt-1">
          <b-avatar
            variant="light-danger"
            class="mr-1"
          >
            <feather-icon
              icon="XIcon"
              size="16"
              class="text-danger"
            />
          </b-avatar>
          <span class="text-dark">Cancelado</span>
        </b-row>
      </div>
    </b-popover>

    <!-- popover-dates -->
    <b-popover
      target="popover-dates"
      variant="primary"
      triggers="hover"
      placement="top"
    >
      <template #title>
        <span>Datas</span>
      </template>
      <div class="p-1 d-flex flex-column align-items-start">
        <b-row class="justify-content-center align-items-center">
          <b-badge
            pill
            :variant="`light-success`"
            class="mr-1"
          >
            C
          </b-badge>
          <span class="text-dark text-nowrap">Data de cadastro</span>
        </b-row>
        <b-row class="justify-content-center align-items-center mt-1">
          <b-badge
            pill
            :variant="`light-info`"
            class="mr-1"
          >
            V
          </b-badge>
          <span class="text-dark text-nowrap">Vencimento do cupom</span>
        </b-row>
      </div>
    </b-popover>

    <!-- MODAL DE DETALHES DA INDICAÇÃO -->
    <b-modal
      id="indications-details"
      hide-footer
      size="lg"
      title="Detalhes da indicação"
    >
      <indication-details-modal @baixa="handleValidate" />
    </b-modal>
    <!-- MODAL DE DETALHES DA INDICAÇÃO -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  BCard, BRow, BCol, BAvatar, BBadge, BButton, VBModal, BPopover, BTable, BPagination, BOverlay, BButtonToolbar, BButtonGroup,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, onBeforeMount } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import formatMobileNumber from '@/utils/formatMobilePhone'
import toConvertDateTimezoneToConventionalDate from '@/utils/toConvertDateTimezoneToConventionalDate'
import Ripple from 'vue-ripple-directive'
import formatCpfCnpj from '@/utils/formatCpfCnpj'
import IndicateListFilters from './IndicateListFilters.vue'
import IndicationDetailsModal from './IndicationDetailsModal.vue'
import useIndicateList from './useIndicateList'
import indicateStoreModule from '../indicateStoreModule'

export default {
  components: {
    IndicateListFilters,
    IndicationDetailsModal,

    BCard,
    BRow,
    BCol,
    // BFormInput,
    BTable,
    BAvatar,
    BBadge,
    BPopover,
    BPagination,
    BButton,
    BOverlay,
    BButtonToolbar,
    BButtonGroup,

    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
  setup() {
    const {
      fetchIndicates,
      fetchCampaigns,
      tableColumns,
      perPage,
      currentPage,
      totalIndicates,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refIndicateListTable,
      refetchData,
      currentCouponDetailsToken,

      // UI
      resolveIndicateStageIcon,
      resolveIndicateStageVariant,
      resolveIndicateStatusVariant,

      // Extra Filters

      statusFilter,
      stageFilter,
      campaignsFilter,
      filterDatesFilter,
      periodDateFilter,
      singleDateFilter,
      isSingleDate,

      //
      campaignsList,
      fetchDetailsCoupon,
    } = useIndicateList()

    const actualCouponSelected = ref(null)
    const formatNumber = number => formatMobileNumber(number)
    const convertDate = date => toConvertDateTimezoneToConventionalDate(date)

    const formatCpfCnpjNumber = number => formatCpfCnpj(number)

    const INDICATE_APP_STORE_MODULE_NAME = 'app-indicate'
    if (!store.hasModule(INDICATE_APP_STORE_MODULE_NAME)) store.registerModule(INDICATE_APP_STORE_MODULE_NAME, indicateStoreModule)
    onUnmounted(() => {
      if (store.hasModule(INDICATE_APP_STORE_MODULE_NAME)) store.unregisterModule(INDICATE_APP_STORE_MODULE_NAME)
    })

    const isFilterIndicateSidebarActive = ref(false)

    const statusOptions = [
      // { label: 'Cupom validado', value: 'Cupom validado' },
      { label: 'Indicação confirmada', value: 'Indicação confirmada' },
      { label: 'Cupom expirado', value: 'Cupom expirado' },
      { label: 'Indicação cancelada', value: 'Indicação cancelada' },
      { label: 'Indicação duplicada', value: 'Indicação duplicada' },
      { label: 'Cupom gerado', value: 'Cupom gerado' },
      { label: 'Cupom ativado', value: 'Cupom ativado' },
    ]
    const stageOptions = [
      { label: 'Em andamento', value: 'Em andamento' },
      { label: 'Concluído', value: 'Concluído' },
      { label: 'Cancelado', value: 'Cancelado' },
    ]
    const filterDatesOptions = [
      { label: 'Data de cadastro', value: 'registrationDate' },
      { label: 'Vencimento do cupom', value: 'expireDate' },
      { label: 'Última atualização', value: 'lastUpdateDate' },
    ]
    onBeforeMount(() => {
      fetchCampaigns()
    })
    const campaignsOptions = campaignsList

    //
    function handleValidate(voucherCode) {
      this.$swal({
        title: '<span class="d-block pt-1 pb-1 font-weight-bolder">Validar indicação</span>',
        html: `
        <p>
          Ao validar a indicação pela <u>primeira vez</u>, <b>será atualizado automaticamente o status</b> de CUPOM GERADO para CUPOM ATIVADO,  o indicador será notificado e <b>você terá até 30 dias para concluir a indicação</b>.
        </p>
        `,

        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: 'Concordar e validar',
        confirmButtonAriaLabel: 'Concordar e validar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        // integração de validação do cupom
        if (result.value) {
          useJwt.validateCoupon(voucherCode)
            .then(response => {
              const infoVoucher = {
                couponToken: response.data.result.couponToken,
                couponType: response.data.result.couponType,
                voucherDescription: response.data.result.campaign.benefitIndicated.benefit,
                expirationDate: response.data.result.expirationDate ? toConvertDateTimezoneToConventionalDate(response.data.result.expirationDate) : '',

                indicatedName: response.data.result.campaign.benefitIndicated.name,
                indicatedEmail: response.data.result.campaign.benefitIndicated.email,
                indicatedPhone: response.data.result.campaign.benefitIndicated.phoneNumber,

                indicatorName: response.data.result.campaign.benefitIndicator.name,
                indicatorReward: response.data.result.campaign.benefitIndicator.type === 'voucher'
                  ? response.data.result.campaign.benefitIndicator.description : response.data.result.campaign.benefitIndicator.amount,
                indicatorRewardMoneyFormat: response.data.result.campaign.benefitIndicator.format,
                currentStatus: response.data.result.currentStatus.status,
                currentStage: response.data.result.currentStatus.stage,
                currentStageDescription: response.data.result.currentStatus.stage.description,
              }
              const queryParam = btoa(JSON.stringify(infoVoucher))

              this.$router.push({
                name: 'confirm-data',
                query: { infoVoucher: queryParam },
              })
            })
            .catch(error => {
              // console.log(error.response.data.errors[0])
              // refetchData()

              //
              this.$swal({
                title: '<span class="d-block pt-1 pb-1 font-weight-bolder">Cupom expirado</span>',
                text: 'Infelizmente o prazo para utilização desse cupom expirou',
                showCloseButton: true,
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonText: 'Continuar',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              }).then(resultOK => {
                if (resultOK.value) {
                  refetchData()
                }
              })
              //

              return error
            })
        }
      })
    }

    function changeStageFilter(stageValue) {
      stageFilter.value = stageValue
      refetchData()
    }

    function setCouponDetails(coupon) {
      actualCouponSelected.value = coupon
      fetchDetailsCoupon(coupon)
    }
    //

    return {

      // Sidebar
      isFilterIndicateSidebarActive,

      fetchIndicates,
      tableColumns,
      perPage,
      currentPage,
      totalIndicates,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refIndicateListTable,
      refetchData,
      handleValidate,
      currentCouponDetailsToken,

      // Filter
      avatarText,

      // UI
      resolveIndicateStageIcon,
      resolveIndicateStageVariant,
      resolveIndicateStatusVariant,
      formatNumber,
      convertDate,
      formatCpfCnpjNumber,

      filterDatesOptions,
      campaignsOptions,
      statusOptions,
      stageOptions,

      // Extra Filters
      filterDatesFilter,
      campaignsFilter,
      statusFilter,
      stageFilter,
      periodDateFilter,
      singleDateFilter,
      isSingleDate,

      // function changeStageFilter
      changeStageFilter,
      setCouponDetails,
      actualCouponSelected,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .swal2-popup {
    background-image: url('/images/alert-bg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0 0;
  }

  .font12px {
    font-size: 12px !important;
  }
</style>
