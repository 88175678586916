<script>
import {
  BRow, BCol, BListGroup, BListGroupItem, BCardText, BOverlay, BButton,
} from 'bootstrap-vue'
import formatMobileNumber from '@/utils/formatMobilePhone'
import toConvertDateTimezoneToConventionalDate from '@/utils/toConvertDateTimezoneToConventionalDate'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import { mapGetters } from 'vuex'
import useIndicateList from './useIndicateList'

export default {
  name: 'IndicationDetailsModal',
  components: {
    BRow,
    BCol,
    BButton,
    BListGroup,
    BListGroupItem,
    BCardText,
    BOverlay,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      detailsCoupon: 'app-indicate/detailsCoupon',
    }),
  },
  setup() {
    const { resolveIconInHistoryCoupon, resolveIndicateStageVariant } = useIndicateList()

    return {
      resolveIconInHistoryCoupon,
      resolveIndicateStageVariant,
      formatNumber: formatMobileNumber,
      convertDate: toConvertDateTimezoneToConventionalDate,
    }
  },
}
</script>
<template>
  <div>
    <b-overlay
      v-if="detailsCoupon === null"
      :show="true"
      rounded="sm"
    >
      <div style="width: 100%; height: 560px;" />
    </b-overlay>

    <div v-else>
      <b-list-group>
        <b-list-group-item
          active
          class="flex-column align-items-start"
        >
          <b-card-text class="mb-1">
            <div class="d-flex w-100 justify-content-between">
              <div>
                <p class="text-white">
                  <b>Código da indicação:</b> {{ detailsCoupon.couponToken }}
                </p>
                <p
                  v-if="detailsCoupon.externalIdentifier"
                  class="font-weight-bold d-block"
                >
                  <b>Código externo:</b> {{ detailsCoupon.externalIdentifier }}
                </p>
              </div>
              <div>
                <p class="text-white text-right">
                  <b>Data de Solicitação:</b> <br> {{ convertDate(detailsCoupon.couponHistory[0].createdIn) }}
                </p>
              </div>
            </div>

            <h5 class="my-1 font-weight-bold text-white">
              <b>Dados da indicação</b>
            </h5>

            <b-row>
              <b-col cols="6">
                <p class="text-white">
                  <b>Nome do indicado:</b> {{ detailsCoupon.campaign.benefitIndicated.name }} <br>
                  <b>CPF:</b> {{ !!detailsCoupon.indicatedCPF ? detailsCoupon.indicatedCPF : 'Não informado' }} <br>
                  <b>Telefone:</b> {{ detailsCoupon.campaign.benefitIndicated.phoneNumber }} <br>
                  <b>Email:</b> {{ detailsCoupon.campaign.benefitIndicated.email }}
                </p>
              </b-col>
              <b-col cols="6">
                <div
                  class="d-flex align-items-start"
                  style="flex: 1"
                >
                  <div class="bg-white text-primary rounded mr-1 p-50">
                    <feather-icon
                      icon="GiftIcon"
                      size="25"
                    />
                  </div>
                  <p class="text-white">
                    <b>Benefício do indicado:</b> <br> {{ detailsCoupon.campaign.benefitIndicated.benefit }}
                  </p>
                </div>
                <p class="text-white">
                  <b>Validade do cupom:</b> {{ !!detailsCoupon.expirationDate ? convertDate(detailsCoupon.expirationDate) : 'Não expira' }}
                </p>
              </b-col>
            </b-row>

          </b-card-text>
        </b-list-group-item>

        <b-list-group-item class="flex-column align-items-start">
          <b-card-text class="mb-1">
            <h5 class="my-1 font-weight-bold">
              <b>Dados do indicador</b>
            </h5>
            <b-row>
              <b-col cols="6">
                <p>
                  <b>Nome do indicador:</b> {{ detailsCoupon.campaign.benefitIndicator.name }} <br>
                  <b>Código de compartilhamento:</b> {{ detailsCoupon.indicatorCampaignToken }} <br>
                </p>
              </b-col>

              <b-col cols="6">
                <div class="d-flex align-items-start justify-content-start">
                  <div class="bg-lighten-2 text-primary rounded mr-1 p-50">
                    <feather-icon
                      icon="GiftIcon"
                      size="25"
                    />
                  </div>
                  <p class="text-primary">
                    <b>Recompensa</b> <br>
                    {{ detailsCoupon.campaign.benefitIndicator.description }}
                  </p>
                </div>
              </b-col>
            </b-row>

          </b-card-text>
        </b-list-group-item>

        <b-list-group-item class="flex-column align-items-start">
          <b-card-text class="mb-1">
            <h5 class="my-1 font-weight-bold">
              <b>Dados da campanha e origem</b>
            </h5>
            <b-row>
              <b-col cols="6">
                <p>
                  <b>Código da campanha:</b> {{ detailsCoupon.campaignToken }}
                </p>
              </b-col>
              <b-col cols="6">
                <p>
                  <b>Tipo de campanha:</b> {{ detailsCoupon.campaign.campaignType === 'leadGenerate' ? 'Geração de lead' : 'Código do indicador' }}
                </p>
              </b-col>
              <b-col cols="12">
                <p>
                  <b>Nome da campanha:</b> {{ detailsCoupon.campaignName }} <br>
                  <b>Página de conversão:</b> {{ detailsCoupon.campaign.conversionUrl }} <br>
                </p>
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col cols="4">
                <p>
                  <b>UTM source:</b> <br> {{ detailsCoupon.utm.source }}
                </p>
              </b-col>
              <b-col cols="4">
                <p>
                  <b>UTM medium:</b> <br> {{ detailsCoupon.utm.medium }}
                </p>
              </b-col>
              <b-col cols="4">
                <p>
                  <b>UTM campaign:</b> <br> {{ detailsCoupon.utm.campaign }}
                </p>
              </b-col>
              <b-col cols="4">
                <p>
                  <b>UTM content:</b> <br> {{ detailsCoupon.utm.content }}
                </p>
              </b-col>
              <b-col cols="4">
                <p>
                  <b>UTM term:</b> <br> {{ detailsCoupon.utm.term }}
                </p>
              </b-col>
              <b-col cols="4">
                <p>
                  <b>UTM referrer:</b> <br> {{ detailsCoupon.utm.referrer }}
                </p>
              </b-col>
            </b-row>
          </b-card-text>
        </b-list-group-item>

        <b-list-group-item class="flex-column align-items-start">
          <b-card-text class="mb-1">
            <h5 class="my-1 font-weight-bold">
              <b>Histórico de interações</b>
            </h5>
            <b-row>
              <b-col cols="12">
                <app-timeline>
                  <app-timeline-item
                    v-for="historyItem in detailsCoupon.couponHistory"
                    :key="historyItem.createdIn"
                    :title="historyItem.status"
                    :subtitle="historyItem.description"
                    :icon="resolveIconInHistoryCoupon(historyItem.stage)"
                    :time="convertDate(historyItem.createdIn, true)"
                    :variant="resolveIndicateStageVariant(historyItem.stage)"
                  />
                </app-timeline>
              </b-col>
            </b-row>
          </b-card-text>
        </b-list-group-item>
      </b-list-group>

      <div class="mt-1 d-flex justify-content-end">
        <b-button
          v-if="detailsCoupon.currentStatus.stage === 'Em andamento'"
          variant="success"
          @click="$emit('baixa', detailsCoupon.couponToken)"
        >
          Dar baixa
        </b-button>
      </div>
    </div>
  </div>
</template>
