import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

const types = {
  SAVE_DETAILS_COUPON: 'saveDetailsCoupon',
}

export default {
  namespaced: true,
  state: {
    indicateDetailsCoupon: null,
  },
  getters: {
    detailsCoupon: state => state.indicateDetailsCoupon,
  },
  mutations: {
    saveDetailsCoupon: (state, payload) => {
      state.indicateDetailsCoupon = payload
    },
  },
  actions: {
    fetchCouponDetails(ctx, { payload }) {
      ctx.commit(types.SAVE_DETAILS_COUPON, null)
      return new Promise((resolve, reject) => {
        useJwt.detailsCoupon(payload)
          .then(
            ({ data }) => {
              const detailsCoupon = data.result
              ctx.commit(types.SAVE_DETAILS_COUPON, detailsCoupon)
            },
          )
          .catch(error => reject(error))
      })
    },
    fetchIndicates(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        useJwt.listIndication({ params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchIndicate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/indicates/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addIndicate(ctx, IndicateData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/indicates', { Indicate: IndicateData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
